import React, { useEffect } from "react";
import { useTheme } from "context/useAppTheme";
import "../webStyles.scss";
import Applogo from 'assets/icons/Applogo.svg';
import ApplogoLight from "assets/icons/AppLogoLight.svg"
import Sidebubble from "../../../../assets/images/ball_edge.png"
import benefit_image from "../../../../assets/images/benefit_rafiki.svg"
import qrcode_img from "../../../../assets/images/qrcode_img.svg"

interface Props { }

const Benefits: React.FC<Props> = (props: Props) => {
    const { isLight } = useTheme();

    useEffect(() => {
        // gsap.registerPlugin(ScrollTrigger)
        //     gsap.to('.logo', {
        //      duration: 3,
        //      scrollTrigger: {
        //          trigger: '.logo',
        //          start: 'top 50%',
        //          end: 'center 90%',
        //          markers: true,
        //          scrub: 1,
        //         //  pin: true,
        //          toggleActions: 'restart none none none',
        //          onEnter: () => console.log("Element .c has been reached!"),
        //      },
        //     x: 0,
        //     skewX: 0,
        //     opacity: 1,
        //     ease: 'none'
        //     })
    }, []);

    return (
        <div className="connect_hero_container w-full">
            <div className="flex justify-center mt-0 lg:pt-12">
                <img alt={'alt-text'} className="absolute -right-[20%] benefit_side_bubble -z-[1]" src={Sidebubble} />
                <div className="lg:w-10/12 md:w-11/12 flex max-w-[1500px] flex-wrap items-center px-3 lg:p-0 relative">
                    <div className="flex flex-row flex-wrap">
                        <div className="w-full lg:w-3/12 mb-6">
                            <img alt={'alt-text'} className="w-full max-w-[235px] logo" src={isLight ? ApplogoLight : Applogo} />
                        </div>
                        <div className="w-full lg:w-9/12 mb-6">
                            <p className={`benefit_titles ${isLight ? 'text-black' : 'text-night_greg'}`}>
                                Welcome to Night Owl, your trusted partner in revolutionizing how you manage table reservations and ticket sales.
                                Our state-of-the-art ticket reservation system is designed
                                to help Business like yours maximize efficiency, increase profits, and provide an exceptional experience for your patrons.
                            </p>

                        </div>
                        <div className="w-full mt-8  mb-20 flex flex-wrap justify-between">
                            <div className="w-full md:w-[55%] mb-6">
                                <img src={benefit_image} alt="rafiki img" />
                            </div>
                            <div className="w-full md:w-[40%] mb-6 flex justify-center flex-wrap">
                                <img src={qrcode_img} alt="rafiki img" />
                                <div className={`w-full   ${isLight ? 'text-black' : 'text-white'}`}>
                                    <p className="bg-[#a39db1] qrcode__section py-8">
                                        <h2 className="text-center text-[34px] font-semibold">Successful</h2>
                                        <span className="text-center text-[17px] block w:11/12 font-normal">
                                            Your table and drinks have been reserved successfully
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Benefits;
