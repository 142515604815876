import stacks from './stack';
import routes from './routes';

import Dashboard from '../pages/Dashboard';
import Waitlist from "../pages/Waitlist"
import LandingPageMobile from 'pages/LandingPageMobile';
import PrivacyPolicy from 'pages/PrivacyPolicy';


const route = [
    // Landing Pages
    {
        stack:stacks.LANDING,
        route: routes.landing,
        Page: Dashboard,
    },
    {
        stack:stacks.LANDING,
        route: routes.waitlist,
        Page: Waitlist,
    },
    {
        stack:stacks.LANDING,
        route: routes.customer,
        Page: LandingPageMobile,
    },
    {
        stack:stacks.LANDING,
        route: routes.privacy,
        Page: PrivacyPolicy,
    },
]


export default route

