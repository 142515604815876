import React, { useRef } from 'react'
import { useTheme } from 'context/useAppTheme'
import { animated, useSpring } from '@react-spring/web'
import { useInView } from 'react-intersection-observer';

interface Props {
    reverseDisplay?: boolean;
    title: string;
    description: string
    src: any
}

function HintsWrapper(props: Props) {
    const { reverseDisplay, description, title, src } = props

    const { isLight } = useTheme()
    const hintWrapper = useRef<HTMLDivElement>(null)
    const imageExpander = useRef<HTMLImageElement>(null)

    const { ref, inView } = useInView({
        triggerOnce: false, // Make sure the animation can trigger multiple times
        rootMargin: '-45% 0px -45% 0px'
    });
    let width = Number(imageExpander?.current?.getBoundingClientRect().width)
    const springProps = useSpring({
        from: { height: 150, marginBottom: 0 },
        to: {
            height: inView ? (width * 0.71513353) : 150,
            marginBottom: inView ? 30 : 0
        }, // The constant value used is the division of image natural weight by the natural height
        config: { duration: 300 }
    });



    return (
        <div className={`flex flex-wrap items-center p-3 sm:p-5 lg:pt-12 px-6 lg:px-8 lg:pb-0 transit ${isLight ? "bg-night_light_blue" : 'bg-[#1E0C4A]'}
         ${reverseDisplay ? 'lg:flex-row-reverse' : ''}
        `}
            ref={hintWrapper}
        >
            <div className='w-full md:w-6/12 mt-3 lg:w-[60%]'>
                <animated.div ref={ref} className="w-full overflow-y-hidden hidden md:inline-block" style={springProps}>
                    <img alt='place-holder' src={src}
                        className={`w-full transit_image_hint object-cover md:block hidden`}
                        // style={{ height: isInterseting ? `${imageHeight}px` : '200px', width: '100%' }}
                        ref={imageExpander}
                    />
                </animated.div>
                <img alt='place-holder' src={src}
                    className={`w-full transit_image_hint object-cover  block md:hidden`}
                />
            </div>
            
            <div className='w-full md:w-6/12 lg:w-[40%] lg:px-6 md:px-3 text-white lg:pb-0 mt-3'>
                <b className={`mb-4 block text-[22px] transit font-[500]  ${isLight ? "text-black" : 'text-white'}`}>{title || ""}</b>
                <p className={`text-[15px] font-[400] transit ${isLight ? "text-black" : 'text-[#BCBCBC]'}`}>{description || ""}</p>
            </div>
        </div>
    )
}

export default HintsWrapper
