import React, { useEffect } from "react";
import { useTheme } from "context/useAppTheme";
import HeroFooterImg from "../../../../assets/images/HeroFooterImg.svg"

interface Props { }


const HeroSection: React.FC<Props> = (props: Props) => {
    const { isLight } = useTheme()

    useEffect(() => {

    }, [])

    return (
        <div className="px-3 pt-3 relative flex justify-center"
        >
            <div className="lg:w-10/12 md:w-11/12 flex max-w-[1500px] flex-wrap items-center px-3 lg:p-0 
            relativeflex justify-between  lg:mb-36 lg:mt-12 mb-8 mt-0">
                {/* <img alt={'alt-text'} className="absolute top-0 left-0 -z-[1] w-[150%]" src={HeroBg} /> */}
                {/* <img alt={'alt-text'} className="absolute -bottom-[20%] left-0 -z-[1] w-[150%]" src={HeroBg} /> */}
                <div className="md:w-8/12 lg:w-6/12 text-center flex flex-wrap justify-center relative mt-4">
                    <img alt={'hero footer img'} className="" src={HeroFooterImg} />
                </div>
                <div className="lg:w-5/12 w-full pb-12 relative mt-4">
                    <p className={`next_level_bold ${isLight ? 'text-black' : 'text-white'}`}>Take Your Business to the Next Level</p>
                    <p className={`next_level_article ${isLight ? 'text-black' : 'text-white'}`}>
                        Ready to upgrade your evening? Book VIP tables, grab event tickets, and invite your crew—all from one easy-to-use app.
                        No lines, no confusion, just an unforgettable night waiting for you. Tap below and experience the difference.
                    </p>
                    <button
                        className={`${isLight ? 'book_btn draw_border_light' : 'book_btn draw_border'} !ml-0 !rounded-2xl !px-8`}
                    >
                       Club page
                    </button>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
