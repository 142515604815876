import React, { useEffect, useState } from "react";
import { useTheme } from "context/useAppTheme";
import HeroBg from "../../../../assets/images/customer_top_blur.png"
import HeaderStroke from "../../../../assets/images/svg/HeaderStroke.svg"
import { gsap } from 'gsap';

interface Props { }


const HeroSection: React.FC<Props> = (props: Props) => {
    const { isLight } = useTheme()
    const [text] = useState("Hello there people")

    useEffect(() => {
        var textWrapper = document.querySelector(".titulo") as any
        textWrapper.innerHTML = textWrapper.textContent.replace(
            /\S/g,
            "<span class='letra'>$&</span>"
        )
        gsap.timeline()
            .from('.titulo .letra', {
                y: 60,  // Equivalent to translateY in Anime.js
                opacity: 0,  // Start with opacity 0
                duration: 1.7,  // Duration in seconds
                ease: "expo.out",  // Equivalent to easeOutExpo
                stagger: {
                    amount: 1.2,  // Total time for staggering (can be adjusted)
                    //@ts-ignore
                    start: 0.4,  // Delay start
                    from: "start",  // Stagger from start
                }
            });
    }, [text]);

    return (
        <div className="px-3 pt-3 relative flex justify-center mb-12"
        >
            {/* <div className="absolute top-0 right-0 w-[25%] h-[100%] bg-night_blue_700 z-[0]"></div> */}
            <div className="lg:w-10/12 md:w-11/12 flex max-w-[1500px] flex-wrap items-center px-3 lg:p-0 relativeflex justify-center mt-28">
                <img alt={'alt-text'} className="absolute top-0 left-0 -z-[1] w-[150%]" src={HeroBg} />
                {/* <img alt={'alt-text'} className="absolute -bottom-[20%] left-0 -z-[1] w-[150%]" src={HeroBg} /> */}
                <div className=" md:w-10/12 lg:w-6/12 text-center flex flex-wrap justify-center relative lg:pt-0 pt-8 z-10">
                    <div>
                        <p className={`hero_heading overflow-y-hidden text-left lexand_font_sm_bold ${isLight ? 'text-night_blue' : 'text-night_pale_blue'}`}>
                            Elevate Your  <b className="relative pr-3">  Night
                                <img alt={'alt-text'} src={HeaderStroke} className="absolute bottom-[3px] right-0 -z-[1] w-[600px]" />
                            </b>
                            Reserve VIP Tables or Snag Tickets <span className="titulo">Instantly.</span>
                        </p>
                        <p className={`hero_title text-left mt-8 ${isLight ? 'text-night_blue' : 'text-night_pale_blue'}`}>
                        Score prime tables or grab event tickets at top clubs in seconds. Get VIP treatment, skip the line, and party like never before.
                        </p>
                        <div className="w-full mt-6 flex justify-start">
                        <button
                         className={`cursor-pointer px-5 text-sm ${isLight ? 'book_btn draw_border_light' : 'book_btn draw_border'}`}
                       >
                        Book your party
                        </button>
                        </div>
                    </div>
                </div>
                <div className="lg:w-6/12 w-full flex justify-center lg:pb-12 lg:pt-0 pt-8 relative">
                    <img alt={'mobile footer img'} className="z-[1]" src={'https://res.cloudinary.com/azudi/image/upload/v1730230660/iPhone_14_Pro_hbtuxh.svg'} />
                    {/* <div className={`absolute bottom-0 left-[50%] w-[100vw] h-[250vh] z-[0]
                        ${ isLight ? 'bg-night_light_blue' : 'bg-night_blue_700'}
                        `}></div> */}
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
