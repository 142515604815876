

const routes = {
  // below for non auth scareen
  
  // below for auth screens
  landing: '/',
  waitlist: '/waitlist',
  customer: '/customer',
  privacy: "/privacy_policy"
}

export default routes